.table-grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Ici, nous utilisons auto-fit pour un layout plus flexible */
    gap: 10px; /* Espace entre les cartes */
    padding: 10px;
  }
  
  .table-card {
    border: 1px solid #ccc; /* Cadre pour chaque carte */
    padding: 10px;
    margin: 10px; /* Espace autour de chaque carte */
    background-color: white; /* Fond de la carte */
    text-align: center; /* Texte centré */
  }
  
  .table-name {
    font-weight: bold; /* Nom de la table en gras */
  }
  