.action-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Ajuste l'espace entre les cartes */
    margin-bottom: 50px;
  }
  
  .action-item {
    flex: 1 1 calc(50% - 20px); /* Ajuste pour l'espacement; calcule la largeur pour 2 colonnes */
  }
  
  .action-item.full-width {
    flex: 0 0 100%; /* Prend toute la largeur pour le premier élément si impair */
  }
  