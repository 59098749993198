.input-field {

    align-items: flex-start;

    padding: 10px;
    border: 1px solid #fff;
    border-radius: 5px;
    background-color: transparent;
    color: #fff;
    outline: none;
  }
  
  .input-field::placeholder {
    color: #aaa;
  }
  
  .label-field {
    color: white;
    font-size: small;
  }
  
  .warning-field {
    font-size: 12px;
    color: var(--warning_color);
    margin-top: 5px;
  }
  
  /* Conteneur pour les champs d'entrée */
  .input-container {
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
  }