:root {
    --selection-color: #FDB813;
}

.action-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100px;
    padding: 20px;
    cursor: default;
    transition: transform 0.2s ease-in-out;
    border-radius: 12px;
    border: 2px solid #ffffff; /* Si un contour est nécessaire */
    box-sizing: border-box; /* Pour inclure padding et border dans la largeur totale */
  }

  .action-card.selected {
    background-color: #FDB813;
  }

  .action-card:not(.selected):hover {
    background-color: #fdb7137d;
  }
  
  .action-card-icon {
    width: 60px; /* Ajuste cette valeur selon la taille de tes icônes */
    margin-bottom: 10px;
  }
  
  .action-card-title {
    color: white;
    font-size: 14px; /* Ajuste la taille de police si nécessaire */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  