:root {
  --couleur_de_fond: #115136; /* Cette couleur sera utilisée pour le fond du textarea */
  --Background_scrollview: #10143353;
  --label_color: #F3BB44; /* Cette couleur peut être utilisée pour le label si nécessaire */
  --warning_color: #f3a144; /* Cette couleur peut être utilisée pour le warniong si nécessaire */
}

.phone-number-container {
  background-color: transparent; /* ou une autre variable correspondant à votre palette de couleurs */
  border-radius: 10px;
  padding: 10px; /* Ajusté pour correspondre à la maquette */
  margin-bottom: 20px; /* Ajustez en fonction de l'espacement souhaité */
}

  .phone-number-container label {
    color: white; /* Change la couleur du texte en blanc */
    font-size: small; /* Modifie la taille du texte en petit */
    font-weight: bold;
  }
  
 /* Si vous voulez des styles spécifiques pour le groupe input et select */
.input-group {
  display: flex; /* ou autre selon votre mise en page */
  gap: 5px; /* ou autre valeur pour l'espace entre le select et l'input */
  margin-top: 35px;
}

  .country-code {
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #fff;
    border-radius: 5px;
    background-color: transparent;
    color: #fff;
  }
  
  input[type='tel'] {
    padding: 10px;
    border: 1px solid #fff;
    border-radius: 5px;
    background-color: transparent;
    color: #fff;
    outline: none;
    width: 200px; /* Ajustez la largeur selon vos besoins */
    font-size: 16px; /* Empêche le zoom sur les appareils mobiles */
  }
  
  input[type='tel']::placeholder {
    color: #aaa;
  }
  
  .warning {
    font-size: 12px;
    color: var(--warning_color); /* Ajustez la couleur pour qu'elle corresponde au design */
    margin-top: 5px; /* Ou ajustez selon l'espacement souhaité */
  }