.button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Assure que le conteneur prend toute la hauteur de la fenêtre */
    text-align: center;
  }
  
  @media screen and (min-width: 1090px) {
    .button-container {
      flex-direction: row;
    }
  }
  