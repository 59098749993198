:root {
    --couleur_de_fond: #115136; /* Cette couleur sera utilisée pour le fond du textarea */
    --Background_scrollview: #10143353;
    --label_color: #F3BB44; /* Cette couleur peut être utilisée pour le label si nécessaire */
  }
  
  .textarea-container {
    background-color: transparent;
    border-radius: 10px;
    padding: 10px; /* Ajusté pour correspondre à la maquette */
    margin-bottom: 20px; /* Ajustez en fonction de l'espacement souhaité */
  }
  
  .textarea-container label {
    color: white; /* Change la couleur du texte en blanc */
    font-size: small; /* Modifie la taille du texte en petit */
  }
  
  .textarea-container textarea {
    padding: 12px; /* Ajusté pour correspondre à la maquette */
    border-radius: 8px;
    border: 2px solid #ffffff; /* Si un contour est nécessaire */
    outline: none;
    resize: none;
    font-size: 16px; /* Ajustez la taille du texte pour le textarea */
    background-color: transparent; /* Remplacez par la couleur de fond réelle du textarea */
    color: white; /* Ajustez si nécessaire pour le contraste */
    width: 100%; /* Assurez-vous que le textarea occupe la largeur disponible */
    box-sizing: border-box; /* Pour inclure padding et border dans la largeur totale */
    margin-top: 35px;
  }
  
  .textarea-container textarea::placeholder {
    color: rgba(255, 255, 255, 0.7); /* Ajustez la couleur du placeholder si nécessaire */
  }
  