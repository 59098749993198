:root {
  --couleur_de_fond: #115136;
  --Background_scrollview: #10143353;
  --tertiary_color: #FFCA28;
}

.background_scrollview {
  background-color: var(--Background_scrollview);
}

body {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--couleur_de_fond);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden; /* Cache le contenu débordant horizontalement */
  max-width: 100vw; /* Empêche le débordement sur la largeur du viewport */
  width: 100vw;
  height: 100vh;
}

.content-container {
  width: 85%;
  padding-block: 16px;
  /* Autres styles pour le contenu principal */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Button {
  height: 40px;
  width: 140px;
  position: relative;
  background-color: white;
  border-radius: 8px;
  border-color: black;
  border: solid;
}

.btn1 {
  translate: 300px;
}

.ReservationView {
  display: block; /* Use flexbox to manage the layout */
  align-items: center; /* Center-align the children horizontally */
  flex-direction: column;
  width: 100%; /* Take the full width of its parent */
  height: 100%; /* Take the full height of its parent */
  background-color: var(--Background_scrollview);
  overflow: auto; /* Enable scrolling if content overflows */
  box-sizing: border-box; /* Include padding in the width and height calculations */
  border-radius: 8px;
}

.ReservationView.hasContent {
  padding-bottom: 25px; /* Apply padding only when there is content */
}

.dropdown {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  top: 100%; /* Positions the dropdown right below the button */
  left: 0;
}

.dropdown-item {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

.phoneNumberField, .commentsField {
  margin-inline: 15px;
  margin-block: 5px;
  border: 2px solid #a7c3d1; /* soft blue border */
  border-radius: 15px; /* rounded corners */
  font-size: 16px; /* larger, readable font */
  font-family: 'Arial', sans-serif;
  outline: none; /* remove focus outline */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* subtle shadow for depth */
  transition: border-color 0.3s; /* smooth transition for interactions */
}

.phoneNumberField:focus, .commentsField:focus {
  border-color: #77a0a9; /* darker shade of border for focus */
}

.commentsField {
  display: block;
  align-items: center;
  justify-content: center;
  width: 85%; /* account for padding */
  height: 120px; /* fixed height, could be adjusted as needed */
  resize: none; /* disable resize */
}

.horizontalsection {
  width: 100%;

}

.horizontalsection h2,
.horizontalsection p {
  color: white; /* Change la couleur du texte en blanc */
  font-size: small; /* Modifie la taille du texte en petit */
}

.verticalsection {
  width: 100%;
  padding-block: 5px;
  padding-inline: 20px;
  display: block;
  align-items: center; /* This centers the children horizontally */
  gap: 10px; /* This adds space between each child */
}

.verticalsection h2,
.verticalsection p {
  color: white; /* Change la couleur du texte en blanc */
  font-size: small; /* Modifie la taille du texte en petit */
}

.calendar-header-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
}

.calendar-title {
  color: white; /* Couleur du texte */
  font-size: small; /* Modifie la taille du texte en petit */
  /* Ajouter d'autres styles pour le titre si nécessaire */
}

.calendar-button {
  width: 150px;
  background-color: white; /* Couleur de fond du bouton */
  color: var(--couleur_de_fond); /* Couleur du texte du bouton */
  border: none; /* Enlever la bordure par défaut */
  padding: 4px 10px; /* Ajouter du padding pour augmenter la taille du bouton */
  /* Ajouter d'autres styles pour le bouton si nécessaire */
  border-radius: 4px;
}

.calendar-container {
  width: 100%;
  /* Appliquer des styles personnalisés à votre composant de calendrier ici */
}

.customers-container {
  width: 100%;
}

.validate-button {
  background-color: var(--tertiary_color); /* Couleur de fond jaune */
  color: var(--couleur_de_fond); /* Couleur de texte noir */
  font-weight: bold;
  border: none; /* Pas de bordure */
  padding: 5px 10px; /* Padding intérieur */
  font-size: 14px; /* Taille de la police */
  border-radius: 8px; /* Bordures arrondies */
  cursor: pointer; /* Curseur en forme de pointeur */
  outline: none; /* Supprimer le contour lors de la sélection */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Ombre légère pour le bouton */
  display: block; /* Assurer que le bouton soit un bloc pour prendre la largeur complète */
  width: 40%; /* Largeur relative par rapport au conteneur */
  margin: 10px auto; /* Centrer le bouton horizontalement avec une marge supérieure et inférieure */
  transition: background-color 0.3s ease; /* Transition fluide pour l'interaction */
}

.validate-button-containe {
  padding-bottom: 100px;
}

.validate-button:hover {
  background-color: #FFB300; /* Couleur de fond plus foncée au survol */
}