:root {
    --global-background: #E2E2E2;
}

.confirmation-page {
    font-family: 'Arial', sans-serif;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
  }

  .confirmation-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px;
    box-sizing: border-box;
  }
  
  .confirmation-header h1 {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 85%;
  }
  
  .confirmation-logo {
    /* Ajoutez votre style ici */
    width: auto;
    transform: scale(1.3); /* Scales the image by 150% */
  }
  
  .confirmation-checkmark {
    /* Ajoutez votre style ici */
    width: 10%;
    height: auto;
  }
  
  .confirmation-content {
    text-align: center;
    background-color: var(--global-background);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 85%;
    height: 80%;
    border-radius: 10px;
    align-items: center;
  }

  .corps-de-page {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  .buttoncomeback {
    height: 25px; /* Hauteur fixe du bouton */
    width: 150px; /* Largeur fixe du bouton */
    background-color: #101433; /* Couleur de fond */
    border: none; /* Pas de bordure */
    border-radius: 5px; /* Rayon de la bordure pour des coins arrondis */
    color: white; /* Couleur du texte */
    font-size: small; /* Taille de la police */
    font-weight: bold; /* Poids de la police */
    text-align: center; /* Centre le texte horizontalement */
    line-height: 25px; /* Centre le texte verticalement */
    display: flex; /* Utilisation de Flexbox */
    justify-content: center; /* Centre horizontalement le contenu (flex) */
    align-items: center; /* Centre verticalement le contenu (flex) */
}


  .reservation-button-block {
    padding-bottom: 20px;
  }
  
  .confirmation-address {
    width: 70%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .reservation-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-block: 15px;
    width: 90%;
  }
  
  .reservation-info {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    align-items: flex-start;
  }

  .underline-style {
    font-weight: bold; /* Rend le texte en gras */
    text-decoration: underline; /* Ajoute un soulignement */
    text-decoration-color: black; /* Change la couleur du soulignement en bleu */
    text-decoration-thickness: 1px; /* Épaissit le soulignement */
  }

  .bas-de-page {
    width: 90%;
  }

  .info-bas-de-page {
    text-align: justify;
  }
  
  .confirmation-footer {
    bottom: 0;
    text-align: center;
    width: 100%;
    padding-top: 45px;
  }

  @media (max-width: 375px) {
    button {
      width: 40%;
      background-color: #ffc107;
      border: none;
      padding: 6px 10px;

      margin-top: 10px;
    }

      .bas-de-page {
        font-size: 14px;
      } 
    
      .reservation-info {
        font-size: 10px;
      }
    
  
      .underline-style {
       font-size: 12px;
      }

      .confirmation-header {
        font-size: 16px;
      }

      .reservation-section {
        margin-block: 10px;
      }
      
  }