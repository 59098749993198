.styled-button {
    display: inline-block;
    width: 200px;
    height: 200px;
    border-radius: 15px;
    background-color: transparent;
    border: 2px solid white; /* Couleur de la bordure */
    color: white; /* Couleur du texte */
    font-size: 18px;
    text-align: center;
    line-height: 200px; /* Centrer le texte verticalement */
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin: 10px;
  }
  
  .styled-button:hover {
    background-color: #FDB813; /* Couleur de fond au survol */ 

    color: #fff; /* Couleur du texte au survol */
  }
  
  .button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  @media screen and (min-width: 1090px) {
    .button-container {
      flex-direction: row;
      justify-content: center;
    }
  }
 

  