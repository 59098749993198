:root {
  --couleur_de_fond: #115136;
  --Background_scrollview: #10143353;
}


/* Dropdown.css */

  .dropdown-container-slots {
    width: 100%;
    /* Ou la largeur que vous souhaitez */
  }

  .dropdown-container-customers {
    width: 100%;
  }
  
  .dropdown-button {
    width: 150px;
    padding: 4px 10px;
    background-color: white;
    color: var(--couleur_de_fond);
    text-align: center;
    border-radius: 4px;
    border: none; /* Enlever la bordure par défaut */
  }

  .drowdown-title {
    color: white; /* Change la couleur du texte en blanc */
    font-size: small;
  }
  
  .dropdown-list-container {
    margin-top: 12px; /* Ajustez cette valeur selon l'espace désiré */
    border-radius: 10px;
    width: 100%;
    max-height: 0; /* Commencez avec max-height à 0 */
    overflow-y: hidden; /* Cachez le contenu tant que max-height est à 0 */
    transition: max-height 0.5s linear; /* Animation de la hauteur */
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  }
  
  .dropdown-list-container.open {
    max-height: 300px; /* Hauteur maximale lorsque le dropdown est ouvert */
    overflow-y: auto; /* Permettre le défilement une fois ouvert */
    border: 1px solid #ccc;
  }
  
  .dropdown-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .dropdown-list-item {
    padding: 10px;
    background-color: white;
    cursor: pointer;
  }

  .non-clickable {
    pointer-events: none; /* Disables click events */
    background-color: grey; /* Sets the background color to grey */
    cursor: default;
  }
  
  
  .dropdown-list-item:hover {
    background-color: #f1f1f1;
  }