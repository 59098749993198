.header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Modifie pour aligner les éléments au début du conteneur */
  text-align: center;
  overflow: hidden; /* Cache le contenu qui dépasse */
  max-height: 150px; /* Hauteur maximale du conteneur */
  min-height: 140px;
  padding-bottom: 20px;
}

.logosista {
  height: auto; /* Assure que la hauteur est proportionnelle à la largeur ajustée */
  width: auto; /* Laissez la largeur ajuster proportionnellement si nécessaire */
  max-height: 100px; /* Ajustez en fonction de l'espace disponible */
  transform: scale(0.5);
  margin-bottom: 5px;
}


.header-address {
  color: white;
  width: 100%; /* Largeur fixe pour le texte */
  margin: 0; /* Aucune marge externe pour aligner correctement */
  font-size: small;
}
