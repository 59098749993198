:root {
    --selection-color: #FDB813;
}

.table-card {
    border: 1px solid #ccc; /* Cadre par défaut pour chaque carte */
    padding: 10px;
    margin: 10px;
    background-color: white; /* Fond par défaut de la carte */
    text-align: center;
    cursor: pointer; /* Montre un curseur de pointeur pour indiquer que la carte est cliquable */
  }
  
  .table-card.selected {
    background-color: var(--selection-color); /* Fond bleu clair pour indiquer la sélection */
    /* Vous pouvez ajuster cette couleur selon votre design */
  }
  
  .table-name {
    font-weight: bold;
  }
  
   .table-name.selected {
    color: white;
  }